import React from 'react';
import computer from '../../../../assets/images/coding-at-computer.png';

const About = () => (
	<div className='about'>
		<div className='about__img-box'>
			<img
				src={computer}
				alt='coding at computer'
				className='about__img-box__photo'
			/>
		</div>
		<article className='about__description-box'>
			<h3 className='heading-tertiary heading-tertiary--xs '>
				about acc software development bootcamp
			</h3>
			<p className='paragraph'>
				<b>Empowerment Through Education</b>
			</p>
			<p className='paragraph--sm'>
				Software development skills are increasingly vital for all industries.
				With shrinking employment opportunities in many disciplines, we fill a
				need for cost effective and short duration technical training to new and
				experienced students.
			</p>
			{/* <div className='about__social-media'>
				<a
					href='https://www.facebook.com/ACCcodingbootcamp'
					rel='noopener noreferrer'
					target='_blank'
					className='about__social-media__icon'
				>
					<i className='fab fa-facebook-f'></i>
				</a>
				<a
					href='https://www.facebook.com/ACCcodingbootcamp'
					rel='noopener noreferrer'
					target='_blank'
					className='about__social-media__icon'
				>
					<i className='fab fa-facebook-f'></i>
				</a>
			</div> */}
		</article>
	</div>
);

export default About;
