import Art from './assets/projectImages/Art-gps.png';

let projects = [
	{
		projectName: 'ArtGPS',
		descriptionDocument:'',
			// 'ArtGPS helps to discover favorite artists and artworks. If you are already art-savvy than you can challenge you knowledge in the art-quiz. Tech stack includes React, PostgreSQL, AWS and Heroku.',
		deployLink: 'http://art-gps.herokuapp.com',
		team: [
			{
				name: 'Dmitry Smirnov',
				selfDescription:
					'My background is in finance and trading. Coding in Python is essential in financial research and automation but I always wanted to create something that many people could use and enjoy. After graduating from ACC Bootcamp I pivoted to web technologies and now working as Quality Engineer at Qualitest supporting Google.',
				linkedIn: 'https://www.linkedin.com/in/dmitry-smirnov-54163b4b/',
			},
			{
				name: 'Nina Smirnova',
				selfDescription:
					'Nina has been working as a journalist and social media manager. She is interested in modern online media, design and entrepreneurship',
				linkedIn: '',
			},
		],
		projectDisplayImage: Art,
	},
];

export default projects;