import React from 'react';
import TeamMember from './TeamMember';

const StudentCard = (props) => {
	const { team, projectDisplayImage, projectName, descriptionDocument, deployLink } = props;
	const teamMember = team.map((member, index) => {
		return (
			<TeamMember
				name={member.name}
				selfDescription={member.selfDescription}
				linkedIn={member.linkedIn}
				key={index}
				index={index}
			/>
		);
	});

	return (
		<article className='student-card student-card__container'>
			<div className='student-card__img-container'>
				<img
					src={projectDisplayImage}
					className='student-card__img-container--img'
					alt={projectName}
				/>
			</div>
			<div className='student-card__project-name u-center-text'>
				<span className='paragraph'>{projectName}</span>
			</div>
			<div className='student-card__team-container'>
				{teamMember}
				<div className='student-card__link-to-docs'>
					<span
						className={
							descriptionDocument
								? 'student-card__link-to-docs__button student-card__link-to-docs__button--description-exist'
								: 'student-card__link-to-docs__button student-card__link-to-docs__button--description-none'
						}
					>
						Description
					</span>
					<a
						href={deployLink}
						target='_blank'
						rel='noopener noreferrer'
						className={
							deployLink
								? 'student-card__link-to-docs__button student-card__link-to-docs__button--deployment-exist'
								: 'student-card__link-to-docs__button student-card__link-to-docs__button--deployment-none'
						}
					>
						Deployment
					</a>
				</div>
			</div>
		</article>
	);
};

export default StudentCard;
