import React, { Component } from 'react';
import axios from 'axios';
import HubspotForm from 'react-hubspot-form';


class Contact extends Component {
	state = {
		contactInfo: {
			name: '',
			email: '',
			phone: '',
			subject: '',
			message: '',
			syllabus: false
		},
		sent: false,
		validationError: false,
		error: false,
		showSpinner: false
	};

	// Handle all text inputs on the contact form
	inputHandler = e => {
		e.preventDefault();
		const name = e.target.name;
		const value = e.target.value;

		this.setState(prevState => ({
			contactInfo: {
				...prevState.contactInfo,
				[name]: value
			}
		}));
	};

	// handle wether or not they want a syllabus
	checkboxHandler = e => {
		e.preventDefault();

		// to set the syllabus state either to true or false.
		let syllabus = !this.state.contactInfo.syllabus;
		this.setState(prevState => ({
			contactInfo: {
				...prevState.contactInfo,
				syllabus: syllabus
			}
		}));
	};

	// When the user presses the send button
	sendHandler = () => {
		const contactInfo = this.state.contactInfo

		// Validate the required fields are at least filled with one character.
		// we can definitely improve this.
		if (!contactInfo.email || !contactInfo.name || !contactInfo.message) {
			return this.setState({ validationError: true });
		} else {
			this.setState({ 
				validationError: false,
				showSpinner: true, 
			});
		}

	

		// send all of the data to our server.
		axios
			.post('/contact', contactInfo)
			.then(res => {
				let error = res.status === 200 ? false : true
				this.setState({
					sent: !error,
					error: error,
					showSpinner: false,
					contactInfo: {
						name: '',
						email: '',
						phone: '',
						subject: '',
						message: '',
						syllabus: true
					}
				});
			})
			.catch(err => {
				this.setState({ error: true });
			});
	};

	render() {
		return (
			<div className='contact' id='hash'>
				<div className='contact__box'>
					<HubspotForm
						portalId='4485525'
						formId='2399d912-9453-41c1-bfd9-a1dd0019cf42'
						loading={<div>Loading...</div>}
					/>
				</div>
			</div>
		);
	}
}

export default Contact;
