import React, {useEffect} from 'react';
import {Switch, Route, useLocation} from 'react-router-dom';
import Layout from './components/layout/Layout';

// ALL PAGES
import Home from './components/pages/home/Home';
import Signup from './components/pages/signup/Signup';
import FAQ from './components/pages/faq/FAQ';
import Education from './components/pages/education/Education';
import TrialClass from './components/pages/TrialClass/TrialClass';
import Team from './components/pages/team/Team';
import StudentProjects from './components/pages/studentProjects/StudentProjects';
import Blog from './components/pages/blog/Blog'


// Push pages the user visits to hubspot and Google analytics tracker
var _hsq = (window._hsq = window._hsq || []);
function usePageViews() {
	let location = useLocation();

	useEffect(() => {

	// Set location in Hubspot
    _hsq.push(['setPath', location.pathname]);
	_hsq.push(['trackPageView']);

	// Set location in Google Analytics
	window.gtag("config", "UA-150854051-1", {
		page_title: location.pathname,
		page_path:location.pathname,
	})
	}, [location]);
}


const App = () => {
  usePageViews()
	return (
		<Layout>
			<Switch>
				<Route exact path='/' component={Home} />
				<Route path='/signup' component={Signup} />
				<Route path='/faq' component={FAQ} />
				<Route path='/education' component={Education} />
				<Route path='/trialclass' component={TrialClass} />
				<Route path='/studentprojects' component={StudentProjects} />
				<Route path='/team' component={Team} />
				<Route path='/blog' component={Blog} />
			</Switch>
		</Layout>
	);
};

export default App;
