import React from 'react';

const StudentHeader = () => (
	<div className='signup u-center-text'>
		<header className='faq-header__text-box'>
			<h2 className='heading-secondary heading-secondary--white'>
				Student Projects
			</h2>
		</header>
	</div>
);

export default StudentHeader;
