import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const FAQgrid = () => (
	<main>
		<section className='faq'>
			<div className='faq__grid-box'>
				<div className='row'>
					<article className='col-1-of-2'>
						<h3 className='heading-grid'>HOW MUCH DOES IT COST?</h3>
						<p className='paragraph--sm paragraph--grey'>
							The 24-week ACC Software Development Bootcamp costs $6,498. &nbsp;
							<a
								href='http://continue.austincc.edu/bootcamp'
								rel='noopener noreferrer'
								target='_blank'
							>
								Details can be found here.
							</a>
						</p>
					</article>
					<article className='col-1-of-2'>
						<h3 className='heading-grid'>WHAT'S COVERED?</h3>
						<p className='paragraph--sm paragraph--grey'>
							This is a Full Stack bootcamp, meaning we cover front end and back
							end technologies. You'll learn: Git, HTML, CSS, jQuery,
							JavaScript, NodeJS, Express, SQL, MongoDB, React and Test-driven
							Development. To view all topics,{' '}
							<Link smooth to='/#hash' className=''>
								get a Copy of the Syllabus
							</Link>
						</p>
					</article>
				</div>

				<div className='row'>
					<article className='col-1-of-2'>
						<h3 className='heading-grid'>WHAT DAY & TIME ARE CLASSES HELD?</h3>
						<p className='paragraph--sm paragraph--grey'>
							We designed this bootcamp with working folks in mind. Classes are
							held on week nights and Saturday mornings.
						</p>
						<p className='paragraph--sm paragraph--grey p-top-10'>
							Your classes may be one of the following schedules:
						</p>
						<ul className='p-top-10'>
							<li className='paragraph--sm paragraph--grey'>
								Mondays, Wednesdays (7p-10p) Saturdays (9a-1p)
							</li>
							<li className='paragraph--sm paragraph--grey'>
								Tuesdays, Thursdays (7p-10p) Saturdays (9a-1p)
							</li>
						</ul>
					</article>
					<article className='col-1-of-2'>
						<h3 className='heading-grid'>
							HOW MANY HOURS PER WEEK SHOULD I EXPECT TO SET ASIDE?
						</h3>
						<p className='paragraph--sm paragraph--grey'>
							About 30 hours in total. Expect to spend 10 hours per week in
							class and 15-20 hours outside of class on self study assignments
							and projects.
						</p>
					</article>
				</div>

				<div className='row'>
					<article className='col-1-of-2'>
						<h3 className='heading-grid'>
							HOW DO I KNOW IF THIS IS RIGHT FOR ME?
						</h3>
						<p className='paragraph--sm paragraph--grey'>
							Here are things you can do to help you decide if this is right for
							you:
						</p>
						<ul>
							<li
								className='paragraph--sm paragraph--grey p-top-10'
							>
								<Link smooth to='/#hash' className=''>
									Review a copy of the bootcamp syllabus.
								</Link>
							</li>
							<li className='paragraph--sm paragraph--grey'>
								<Link smooth to='/team'>
									{' '}
									Schedule a free one-on-one phone call{' '}
								</Link>{' '}
								with one of our instructors. It's not a sales call. We are here
								to help.
							</li>
							<li className='paragraph--sm paragraph--grey'>
								Enroll in the&nbsp;
								<a
									href='https://continue.austincc.edu/schedule/schedule.php?op=browse&ct=CE&snid=27145&term=221FCE'
									rel='noopener noreferrer'
									target='_blank'
								>
									2-day trial class.
								</a>{' '}
								The trial class will give you a taste for the pace, instructor
								style and teach you the basics of HTML, CSS and jQuery!
							</li>
							<li className='paragraph--sm paragraph--grey'>
								Attend a virtual Q&A session with former students and ACC
								instructors.{' '}
								<Link smooth to='/#hash' className=''>
									{' '}
									Contact us{' '}
								</Link>{' '}
								for more information about the next Q&A session.
							</li>
						</ul>
					</article>
					<article className='col-1-of-2'>
						<h3 className='heading-grid'>WHERE IS CLASS HELD?</h3>
						<p className='paragraph--sm paragraph--grey'>
							Classes are normally held at the ACC Highland Business Center
							Campus.
						</p>
						<p className='paragraph--sm paragraph--grey'>
							<a
								href='https://goo.gl/maps/HeX1BKxa1MkEJQC2A'
								rel='noopener noreferrer'
								target='_blank'
							>
								5930 Middle Fiskville Rd, Austin, TX 78752
							</a>
						</p>
						<p className='paragraph--sm paragraph--grey p-top-10'>
							<b>Covid-19 Update:</b> Currently all of our classes are held
							using live virtual classes using tools including Zoom, Slack,
							Webex and Github.
						</p>
					</article>
				</div>

				<div className='row'>
					<article className='col-1-of-2'>
						<h3 className='heading-grid'>
							WHAT HAPPENS IF I'M SICK OR OUT OF TOWN?
						</h3>
						<p className='paragraph--sm paragraph--grey'>
							You may attend class via live video conference. However such
							virtual attendance is not recommended too often.
						</p>
					</article>
					<article className='col-1-of-2'>
						<h3 className='heading-grid'>
							WILL I RECEIVE A CERTIFICATE FOR COMPLETING THE CLASS?
						</h3>
						<p className='paragraph--sm paragraph--grey'>
							You will receive an ACC Certificate of Completion.
						</p>
					</article>
				</div>

				<div className='row'>
					<article className='col-1-of-2'>
						<h3 className='heading-grid'>I'M READY! HOW CAN I PREPARE?</h3>
						<p className='paragraph--sm paragraph--grey'>
							Great! Here's how to prepare:
						</p>
						<ul>
							<li className='paragraph--sm paragraph--grey'>
								If you haven't already, be sure to enroll in the next upcoming
								&nbsp;
								<a
									href='https://continue.austincc.edu/schedule/schedule.php?op=browse&ct=CE&snid=27145&term=221FCE'
									rel='noopener noreferrer'
									target='_blank'
								>
									2-Day Trial Class.
								</a>
								&nbsp; This is a great way to learn the basics and get to know
								your instructors.
							</li>
							<li className='paragraph--sm paragraph--grey'>
								<Link to='/team'>
									Schedule a time to chat with one of our instructors by phone.
								</Link>
							</li>
							<li className='paragraph--sm paragraph--grey'>
								Once enrolled, you will be given access to an online course
								created by one of the ACC instructors, specifically for
								beginners. It is a great way to prepare for the software
								bootcamp.
							</li>
						</ul>
					</article>
					<article className='col-1-of-2'>
						<h3 className='heading-grid'>
							DOES THE CLASS QUALIFY FOR FINANCIAL AID?
						</h3>
						<p className='paragraph--sm paragraph--grey'>
							Unfortunately, financial aid is not applicable since this is a
							continuing education class and cannot be combined or applied to a
							degree plan.
						</p>
						<p className='paragraph--sm paragraph--grey p-top-10'>
							Funding is available for Travis and surrounding county residents
							who are currently unemployed through Workforce Solutions.
						</p>
						<p className='paragraph--sm paragraph--grey p-top-10'>
							Click the links below for more information:
						</p>
							<ul>
								<li>
									<a
										href='http://www.wfscapitalarea.com/JobSeekers/JobTraining/WIOA'
										rel='noopener noreferrer'
										target='_blank'
									>
										Capital Area Workforce Solutions
									</a>
								</li>
								<li>
									<a
										href='https://workforcesolutionsrca.com/seekers/our-services'
										rel='noopener noreferrer'
										target='_blank'
									>
										Rural Capital Area Workforce Solutions
									</a>
								</li>
							</ul>
					</article>
				</div>
			</div>
			<div className='faq__btn-box u-center-text'>
				<a
					href='mailto:softwarebootcampacc@gmail.com?bcc=4485525@bcc.hubspot.com'
					className='btn btn--lg btn--transparent-black btn--transparent-black--white'
				>
					I Still Have a Question
				</a>
			</div>
			<hr />
		</section>
	</main>
);

export default FAQgrid;
