import React from 'react';
import TestDrive from './TestDrive/TestDrive';

const TrialClass = () => (
	<main>
		<TestDrive />
	</main>
);

export default TrialClass;
