import React from 'react';
import policy from '../../../assets/policy.pdf'

const Footer = () => (
	<footer className='footer'>
		<div className='footer__social-box'>
			<a
				href='https://www.facebook.com/ACCcodingbootcamp'
				rel='noopener noreferrer'
				target='_blank'
				className='footer__link'
			>
				<i className='fab fa-facebook-f'></i>
			</a>
			<a
				href='https://twitter.com/accsoftwaredev'
				rel='noopener noreferrer'
				target='_blank'
				className='footer__link'
			>
				<i className='fab fa-twitter'></i>
			</a>
		</div>
		<div className="footer__policy">
			<a 
				href={policy} 
				className="policy__link footer__link"
				rel='noopener noreferrer'
				target='_blank'
			>
				Policy
			</a>
		</div>
		<div className='footer__copyright-box'>
			<p className='paragraph paragraph--sm'>
				©2021 by ACC Software Development Bootcamp.
			</p>
		</div>
	</footer>
);

export default Footer;
