import React from 'react';

export default function Video() {
	return (
        <div className='about-video u-center-text'>
			<iframe
                className="youtube"
                title="About the bootcamp"
				width='900'
				height='500'
				src='https://www.youtube.com/embed/BnzsAO_-Ssg'
				frameBorder='0'
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				allowFullScreen
			></iframe>
		</div>
	);
}
