import React from 'react';
import FAQheader from './FAQheader/FAQheader';
import FAQgrid from './FAQgrid/FAQgrid';

const FAQ = () => (
	<main>
		<FAQheader />
		<FAQgrid />
	</main>
);

export default FAQ;
