import React, { Component } from 'react';

// Home page components
import Contact from './contact/Contact';
import Hero from './hero/Hero';
import About from './about/About';
import Video from './video/Video'
class Home extends Component {
	render() {
		return (
			<main>
				<Hero />
				<About />
				<Video />
				<Contact />
			</main>
		);
	}
}

export default Home;
