import React from 'react';

const iFrameStyle = {
    border: 'none',
    overflow: 'hidden'
}


const Blog = () => {
	return (
		<main className='blog'>
			<div className='blog__post'>
				<iframe
					src='https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FACCcodingbootcamp%2Fposts%2F1204369323273083&show_text=true&width=552&height=218&appId'
					width='552'
					height='218'
					title='Bootcamp Thoughts'
					style={iFrameStyle}
					scrolling='no'
					frameBorder='0'
					allowtransparency='true'
					allow='encrypted-media'
				></iframe>
			</div>
		</main>
	);
};

export default Blog;
