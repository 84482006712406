import React from 'react';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';

const TeamMember = props => {
    const { name, selfDescription, linkedIn } = props;
    const tooltip = (
			<div className='student-card__team-member__tooltip'>
				<div className='student-card__team-member__tooltip--description'>
					{selfDescription}
				</div>
				<a
					href={linkedIn}
					target='_blank'
					rel='noopener noreferrer'
					className={
						linkedIn
							? 'student-card__team-member__tooltip--linkedin u-center-text'
							: 'student-card__team-member__tooltip--linkedin student-card__team-member__tooltip--linkedin-none u-center-text'
					}
				>
					LinkedIn
				</a>
			</div>
		);
    return (
			<div className='student-card__team-member'>
				<span
					data-tip
					data-for={name}
					className='student-card__team-member--name paragraph'
				>
					{name}
				</span>
				<ReactTooltip
					id={name}
					delay={1000}
					place='right'
					type='dark'
					effect='solid'
					className='__react_component_tooltip'
                    clickable={true}
                    html={true}
				>
					{ReactDOMServer.renderToStaticMarkup(tooltip)}
				</ReactTooltip>
			</div>
		);
}

export default TeamMember;