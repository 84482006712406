import React from 'react';
import { openPopupWidget } from 'react-calendly'

const calendlyOptions = {
	url: 'https://calendly.com/accsoftwarebootcamps',
};

const ChatContact = () => (
	<div>
		{openPopupWidget(calendlyOptions)}
	</div>
);

export default ChatContact;