import React from 'react';
import StudentHeader from './StudentHeader/StudentHeader'
import StudentCardContainer from './studentCardContainer/StudentCardContainer'

const StudentProjects = () => {
    return (
			<main>
				<StudentHeader />
                <StudentCardContainer />
			</main>
		);
}

export default StudentProjects;