import React from 'react';

const Hero = () => (
	<div className='hero'>
		<header className='hero__heading u-center-text'>
			<h2 className='heading-secondary heading-secondary--white heading-secondary--bold'>welcome</h2>
			<p className='heading-secondary--custom'>Future-proof your skillset</p>
		</header>
	</div>
);

export default Hero;
