import React from 'react';
import TeamHeader from './TeamHeader/TeamHeader';
import TeamMembers from './TeamMembers/TeamMembers';

const Team = () => (
	<main>
		<TeamHeader />
		<TeamMembers />
		<hr />
	</main>
);

export default Team;
