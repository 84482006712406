import React from 'react';
import ReactDOM from 'react-dom';
import './sass/main.scss';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App';

import Amplify from "aws-amplify";
import awsExports from './aws-exports';
	Amplify.configure(awsExports);

ReactDOM.render(
	<Router>
		<App />
	</Router>,
	document.getElementById('root')
);
