import React from 'react'
import StudentCard from '../studentCard/StudentCard'
import projects from '../../../../projects'

const StudentCardContainer = () => {
    let cards = projects.map((project, index) => {
        return (
            <StudentCard 
                descriptionDocument={ project.descriptionDocument }
                projectDisplayImage={ project.projectDisplayImage }
                key={ index }
                projectName={ project.projectName }
                team={ project.team }
                deployLink={ project.deployLink }
            />
        )
    })
    return (
        <div className="student-card-container">
            {cards}
        </div>
    )
}

export default StudentCardContainer;